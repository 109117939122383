import React from 'react'
import FullLayout from '../components/fullLayout'
import SEO from '../components/seo'

const Online = () => (
  <FullLayout>
    <div
      style={{
        padding: '56.25% 0 0 0',
        position: 'relative',
      }}
    >
      <SEO title="Online Yoga" />

      <iframe
        src="https://vimeo.com/showcase/8346438/embed"
        allowfullscreen
        frameborder="0"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      ></iframe>
    </div>
  </FullLayout>
)

export default Online
